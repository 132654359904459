import React from 'react'

const ParagraphInset = (props) => {

    const { content, classNames } = props
    const inset = content && content?.inset ? content.inset : '<p></p>'
    return (
        <div className={`${classNames ? classNames : ''}`} dangerouslySetInnerHTML={{ __html: inset }}></div>
    )
}

export default ParagraphInset
