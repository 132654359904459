import React from 'react'

const PageHeader = (props) => {

    const { title, headTag } = props
    return (
        headTag && headTag === "h1" ?
        <h1>{title}</h1>
        : <h2 className="section-heading">{title}</h2>
    )
}

export default PageHeader
