import React from 'react'

const paragraphText = (props) => {

    const { content, nocol } = props
    const image = content?.relationships?.image?.uri?.url
    const data = content && content?.htmlText?.processed ? content.htmlText.processed : null

    return (
        typeof(image) !== "undefined" ? 
        (  <div className="row">
            <div class="col-md-6">
                <figure>
                    <img src={image} alt="The Background"  className="br-20 ls-is-cached lazyloaded"/> 
                </figure>
            </div>
                <div class="col-md-6 para_text">
                  <div dangerouslySetInnerHTML={{ __html: data }}/>
                </div>
        </div>
        ):  
            <div className={!nocol ? "row" : ""}>
                {nocol ? 
                <div dangerouslySetInnerHTML={{ __html: data }}></div>
                :
                <div className="col-md-12">
                    <div dangerouslySetInnerHTML={{ __html: data }}></div>
                </div>
            }
            </div>
    )
}

export default paragraphText
